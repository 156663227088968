<template>
  <div>
    <img src="../assets/images/test.png">
    <div class="date">{{date}}<span>{{time}}</span></div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data(){
    return {
      date: dayjs().format('YYYY-MM-DD HH:mm'),
      time: dayjs().format(':ss'),
    }
  },
  mounted() {
    var _this = this
    setInterval(() => {
      _this.date = dayjs().format('YYYY-MM-DD HH:mm')
      _this.time = dayjs().format(':ss')
    }, 1000)
  },
}
</script>

<style>
img {
  width: 100%;
}

.date {
  position: absolute;
  top: 160px;
  left: 215px;
  z-index: 1000;
}

.date span {
  font-size: 20px;
  font-weight: bold;
}
</style>