<template>
    <div class="home">
        <el-container>
            <el-main>
                <div class="main-area">
                    <img src="../assets/images/logo.png">
                </div>

                <div class="block">
                    <h5><span>* 设计与开发 *</span></h5>
                    <h6>好看还不够，好用更重要</h6>
                    <div class="line header-1"></div>
                    <div class="text-list">
                        <h4>不轻旗下自营的自习室</h4>
                        <p>传单、海报及在线banner设计</p>
                        <p>管理系统及小程序开发</p>
                    </div>
                    <div class="text-list">
                        <h4>知名画家</h4>
                        <p>官方网站的设计及开发制作</p>
                    </div>
                    <div class="text-list">
                        <h4>某时尚品牌</h4>
                        <p>移动端购物商城开发</p>
                    </div>
                    <div class="text-list">
                        <h4>某医疗连锁机构</h4>
                        <p>会员端小程序UI设计</p>
                        <p>就诊系统及CRM小程序开发</p>
                        <p>生长发育骨龄评估报告开发</p>
                        <p>手术预约系统开发（含小程序端）</p>
                    </div>
                </div>

                <div class="block">
                    <h5><span>* 手绘与插画 *</span></h5>
                    <h6>绘画最是长情</h6>
                    <div class="line header-2"></div>
                    <div class="image-list">
                        <img src="../assets/images/article1.jpg">
                        <img src="../assets/images/article2.jpg">
                    </div>
                    <div class="image-list">
                        <img src="../assets/images/article3.jpg">
                        <img src="../assets/images/article4.jpg">
                    </div>
                    <div class="image-list">
                        <img src="../assets/images/article5.jpg">
                        <img src="../assets/images/article6.jpg">
                    </div>
                    <div class="image-list">
                        <img src="../assets/images/article7.jpg">
                        <img src="../assets/images/article8.jpg">
                    </div>
                </div>

                <div class="block">
                    <h5><span>* 自习室 *</span></h5>
                    <h6>创始人小姐姐本来只是自己用，但独乐不如众乐</h6>
                    <div class="line header-3"></div>
                    <div class="icon-list">
                        <div class="list-col">
                            <img src="../assets/images/icon1.png">
                            <span>自习</span>
                        </div>
                        <div class="list-col">
                            <img src="../assets/images/icon2.png">
                            <span>阅读</span>
                        </div>
                        <div class="list-col">
                            <img src="../assets/images/icon3.png">
                            <span>绘画</span>
                        </div>
                        <div class="list-col">
                            <img src="../assets/images/icon4.png">
                            <span>手工</span>
                        </div>
                    </div>
                    <div class="text-list">
                        <h4>可做手工的自习室</h4>
                        <p>观山、望川和手工区三种坐席</p>
                        <p>有独立单人包间</p>
                        <p>免费提供咖啡、茶水、饮料和小零食</p>
                    </div>
                    <div class="text-list">
                        <h4>门店信息</h4>
                        <p>南翔留云湖店</p>
                        <p>营业时间：周一至周日 8：30-22：30</p>
                        <p>联系电话：021-59969331</p>
                        <p>门店地址：嘉定区南翔镇智翔路8号401室（幻月城B栋，罗森楼上）</p>
                    </div>
                </div>

                <div class="block">
                    <h5><span>* 手工 *</span></h5>
                    <h6>玩也是一种工作，得认真对待</h6>
                    <div class="line header-4"></div>
                    <div class="text-list">
                        <h4>手绘体验</h4>
                        <p>手绘风铃、明信片以及其他尺寸的手绘</p>
                    </div>
                    <div class="thumb-list">
                        <img src="../assets/images/hand1.jpg">
                        <img src="../assets/images/hand2.jpg">
                        <img src="../assets/images/hand3.jpg">
                    </div>
                    <div class="text-list">
                        <h4>粘土制作</h4>
                        <p>粘土小饰品或者粘土画的制作</p>
                        <p>老师一对一讲解制作方法，作品可当场带回家</p>
                    </div>
                    <div class="thumb-list">
                        <img src="../assets/images/hand4.jpg">
                        <img src="../assets/images/hand5.jpg">
                        <img src="../assets/images/hand6.jpg">
                    </div>
                    <div class="text-list">
                        <h4>钩针体验</h4>
                        <p>钩针制作围巾、小包包、玩偶衣服以及杯垫等</p>
                    </div>
                    <div class="thumb-list">
                        <img src="../assets/images/hand7.jpg">
                        <img src="../assets/images/hand8.jpg">
                        <img src="../assets/images/hand9.jpg">
                    </div>
                    <div class="text-list">
                        <p>* 以上体验，均为老师一对一讲解制作方法，作品可当场带回家</p>
                    </div>
                </div>

                <div class="block">
                    <h5><span>* 关于我们 *</span></h5>
                    <div class="line header-5"></div>
                    <div class="text-list">
                        <h4>关于不轻</h4>
                        <p>不轻成立于2021年早夏，致力于手绘、手工的制作和推广，并且为各种好看的好玩的小程序和网站提供设计和制作。</p>
                        <p>不轻还拥有一家温馨的自习室，喜欢手绘和手工的小伙伴一定要来店里看看哦~</p>
                    </div>
                    <div class="text-list">
                        <h4>公司地址</h4>
                        <p>嘉定区南翔镇智翔路8号401室</p>
                    </div>
                    <div class="text-list">
                        <h4>联系方式</h4>
                        <p>电话：021-59969331</p>
                        <p>手机：18917636013</p>
                        <p>邮件：it@viewinn.cn</p>
                    </div>
                    <div class="thumb-list">
                        <div>
                            <img src="../assets/images/qr1.jpeg">
                            <span>公众号</span>
                        </div>
                        <div>
                            <img src="../assets/images/qr2.jpeg">
                            <span>预约小程序</span>
                        </div>
                        <div>
                            <img src="../assets/images/qr3.jpeg">
                            <span>加好友</span>
                        </div>
                    </div>
                </div>
            </el-main>
            <el-footer>
                <div>上海不轻文化传播有限公司</div>
                <div>
                    <p>Copyright © 2014-2019 by 不轻文化. </p>
                    <p>All Rights Reserved. </p>
                    <p><a href="https://beian.miit.gov.cn/">沪ICP备2021012796号</a></p>
                    <p><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009317">沪公网安备31011402009317号</a></p>
                </div>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style lang="scss">
body {
    margin: 0;
    padding: 0;
    background: #f7f7f7;
}
</style>

<style lang="scss" scoped>
.home {
    // padding-top: 200px;
}

.el-header, .el-footer {
    // background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
    padding-bottom: 20px;

    p {
        margin: 0;
        padding: 0;
        line-height: 21px;

        a {
            color: #333;
        }
    }
}

.el-main {
    padding: 0;
}

body > .el-container {
// margin-bottom: 40px;
}

// .el-container:nth-child(5) .el-aside,
// .el-container:nth-child(6) .el-aside {
// line-height: 260px;
// }

// .el-container:nth-child(7) .el-aside {
// line-height: 320px;
// }

.main-area {
    background: url(../assets/images/header-bg.jpg) no-repeat;
    background-size: 100% auto;
    display: flex;
    width: 100vw;
    height: 85vw;
    justify-content: center;
    padding-top: 15vw;

    img {
        width: 50vw;
        height: 50vw;
    }
}

.block {
    width: 100vw;
    
    h5 {
        width: 100vw;
        // height: 15vw;
        // line-height: 15vw;
        text-align: center;
        font-size: 18px;
        margin-bottom: 5px;

        span {
            display: inline-block;
            width: 150px;
            padding: 5px 12px;
            border-radius: 5px;
            background: #e2a880;
            color: white;
        }
    }

    h6 {
        width: 100vw;
        // height: 15vw;
        // line-height: 15vw;
        text-align: center;
        font-size: 14px;
        margin: 0;
        color: #666;
    }

    .line {
        width: 100vw;
        height: 35vw;

        &.header-1 {
            background: url(../assets/images/block-header-bg1.jpg) no-repeat left bottom;
            background-size: 100% auto;
        }

        &.header-2 {
            background: url(../assets/images/block-header-bg2.jpg) no-repeat left bottom;
            background-size: 100% auto;
        }

        &.header-3 {
            background: url(../assets/images/block-header-bg3.jpg) no-repeat left bottom;
            background-size: 100% auto;
        }

        &.header-4 {
            background: url(../assets/images/block-header-bg4.jpg) no-repeat left bottom;
            background-size: 100% auto;
        }

        &.header-5 {
            background: url(../assets/images/block-header-bg5.jpg) no-repeat left bottom;
            background-size: 100% auto;
        }
    }

    .text-list {
        font-size: 20px;
        line-height: 42px;
        padding: 20px;

        h4 {
            text-align: left;
            margin: 0;
        }

        p {
            text-align: left;
            margin: 0;
            font-size: 14px;
            line-height: 21px;
        }
    }

    .image-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-left: 4vw;
        margin-bottom: 4vw;

        img {
            width: 44vw;
            height: 44vw;
        }
    }

    .icon-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        // padding-left: 10vw;
        margin-bottom: 2vw;

        .list-col {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            width: 12vw;
            height: 12vw;
        }
    }

    .thumb-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding-left: 3vw;
        margin-bottom: 3vw;

        img {
            width: 30vw;
            height: 30vw;
        }
    }
}
</style>